<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group
            novalidate
            class="needs-validation"
            label="Link/ID"
            label-for="videoLink"
          >
            <b-form-input
              id="videoLink"
              v-model="videoLink"
              placeholder="https://www.twitch.tv/videos/1681433578"
            />

          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Anzahl der Views"
            label-for="amount"
          >
            <b-form-input
              id="amount"
              v-model="amount"
              placeholder="30"
            />
            <!--          <b-form-valid-feedback-->
            <!--            tooltip-->
            <!--          >-->
            <!--            Looks good!-->
            <!--          </b-form-valid-feedback>-->
            <b-form-invalid-feedback
              tooltip
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Dripfeed insgesamt (in Minuten)"
            label-for="dripfeedTotal"
          >
            <b-form-input
              id="dripfeedTotal"
              v-model="dripfeedTotal"
              placeholder="Geminos"
            />

          </b-form-group>
        </b-col>
        <b-col>
          <!--          <b-form-group-->
          <!--            label="Views pro Stunde"-->
          <!--            label-for="dripfeedProStunde"-->
          <!--          >-->
          <!--            <b-form-input-->
          <!--              id="dripfeedProStunde"-->
          <!--              v-model="dripfeedProStunde"-->
          <!--              placeholder="5"-->
          <!--            />-->
          <!--          </b-form-group>-->
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            block
            variant="success"
            @click="startOrder"
          >
            Auftrag starten!
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <!--  Here comesTheTable-->
      <b-row v-if="waitingActive">
        <b-col>
          <b-progress
            v-model="barProgress"
            max="100"
          />
        </b-col>

      </b-row>
      <b-row class="m-2">
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          md="2"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-checkbox
            v-model="showOlderOrders"
            name="check-button"
            switch
            inline
          >
            Zeige alte Orders (3Tage+)
          </b-form-checkbox>

        </b-col>
        <b-col
          md="2"
          sm="8"
          class="my-1"
        >
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    -- none --
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            style="min-height: 30vh"
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(progress)="data">
              <b-progress
                :max="data.item.totalAdd"
                :variant="`${resolveProgressBarVariant(data.item)}`"
              >
                <b-progress-bar :value="data.item.totalAdd - data.item.toAdd">
                  <strong>{{ data.item.totalAdd - data.item.toAdd }} / {{ data.item.totalAdd }}</strong>
                </b-progress-bar>

              </b-progress>
              <!--          <b-badge-->
              <!--            pill-->
              <!--            :variant="`light-${resolveUserStatusVariant(data.item.duration)}`"-->
              <!--            class="text-capitalize"-->
              <!--          >-->
              <!--            {{ data.item.duration }}-->
              <!--          </b-badge>-->
            </template>
            <template #cell(alreadySent)="data">
              {{ data.item.alreadySent }}
            </template>
            <template #cell(dripfeedTime)="data">
              {{ data.item.dripfeedTime }}
            </template>
            <template #cell(viewNumbers)="data">
              <b-badge :variant="getVariant(data.item)">
                {{ data.item.startingViewCount }} / {{ data.item.finishedViewCount }}
              </b-badge>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(creationDate)="data">
              {{ getFormattedDate(data.item.creationDate) }}
            </template>

            <template #cell(lastFollow)="data">
              {{ getFormattedDate(data.item.lastFollow) }}
            </template>
            <template
              v-if="isjemkein"
              #cell(id)="data"
            >
              <b-link
                v-if="isjemkein"
                :href="'https://console.firebase.google.com/u/0/project/geminos-4dedd/firestore/data/~2FfollowerFabrik~2Forders~2Ffollow~2F' + data.item.uid"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.id }}
              </b-link>
            </template>
            <template #cell(link)="data">
                {{ data.item.link }}
            </template>
          </b-table>
        </b-col>

      </b-row>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ items.length }} to {{ items.length }} of {{ items.length }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <!--              totalUserss-->
            <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Login"
      cancel-title="Close"
      centered
      title="Login Form"
    >
      Change Twitchname for {{ selectedUser.twitchname }}
      <b-form>
        <b-form-group>
          <label>Twitchname</label>
          <b-form-input
            v-model="newTwitchname"
            type="text"
            placeholder="Twitchname"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="success"
          @click="saveTwitchname(selectedUser,newTwitchname), ok()"
        >
          Save
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'

/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BBadge,
  BLink,
  BRow,
  BProgress,
  BModal,
  VBModal,
  BForm,
  BProgressBar,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {Timestamp} from "firebase/firestore";


export default {
  components: {
    BTable,
    BAvatar,
    BProgress,
    BProgressBar,
    BCard,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    BRow,
    BModal,
    BForm,
    BFormCheckbox,
    BFormInput,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      showOlderOrders: false,
      twitchname: "",
      amount: 500,
      dripfeedTotal: 60,
      dripfeedProStunde: 60,
      barProgress: 0,
      totalActiveViews: 0,
      waitingActive: false,
      selectedUser: { "id": 47, "geminosname": "Notnetic", "twitchname": "ducknetic", "license": "3437-3886-3296", "duration": -56, "durationLeft": -56, "durationTotal": 103, "plan": "Starter", "viewers": { "amountString": "20/15", "statusOfBadge": "light-success" }, "status": 2, "uid": "4Mh9ehv8nrYN8ABkLfinQI7I2yH3" },
      perPage: 20,
      pageOptions: [10, 20, 35, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      isjemkein: false,
      sortDesc: true,
      videoLink: null,
      newTwitchname: "",
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id', label: 'Geminos-Id', sortable: true },
        {
          key: 'progress', label: 'progress',
        },
        { key: 'link', label: 'Link', sortable: true },
        { key: 'remaining', label: 'Remaining', sortable: true },
        { key: 'alreadySent', label: 'Sent', sortable: true },
        { key: 'dripfeedTime', label: 'dripfeedTime', sortable: true },
        {
          key: 'viewNumbers', label: '[Start/End]', sortable: true },
        { key: 'creationDate', label: 'Created' , sortable: true },
      ],
      items: [
        {
          id: 1,
          // eslint-disable-next-line global-require
          geminosname: 'DisATest',
          twitchname: "Korrie O'Crevy",
          license: 'Nuclear Power Engineer',
          plan: 'kocrevy0@thetimes.co.uk',
          viewers: 'Krasnosilka',
          duration: '10',
          status: 2,
        },
      ],
      status: [{
        1: 'Inactive', 2: 'Active', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-danger', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    getFormattedDate2(oldDate) {
      console.log("Here")
      console.log(oldDate)
      return formatDate(oldDate.toDate(), { dateStyle: 'full', timeStyle: 'short' })
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    console.log("NOIW")
    this.resolveUserData()
    // this.refreshForCurrentViews()
    this.listenForUpdates()
    this.totalRows = this.items.length
  },
  watch: {
    showOlderOrders() {
      this.listenForUpdates()
    },
    dripfeedTotal() {
      this.dripfeedProStunde = 60*(60/this.dripfeedSeconds)
    },
    dripfeedProStunde() {
      this.dripfeedSeconds = (60/this.dripfeedProStunde)*60
    }
  },
  methods: {
    getVariant(item) {
      if(((item.finishedViewCount - item.startingViewCount)< item.totalAdd) && item.finished) return "light-danger"
      if(((item.finishedViewCount - item.startingViewCount)< item.totalAdd) && !item.finished) return "light-warning"
      return "light-success"
    },
    startOrder() {
      axios.post('/api/v1/startOrder', {
        key: "followerfabrik5196129",
        type: "vodClip",
        link: this.videoLink,
        quantity: Math.round(this.amount),
        dripfeedTime: Math.round(this.dripfeedTotal* 60)
      })
          .then((response) =>  {
            console.log(response);
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Created Order!`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Created Order!`,
              },
            })
            this.$swal({
              title: 'Created Order',
              text: 'Created Order',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              title: 'Errors!',
              text: 'Something went wrong please check all fields',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          });
    },
    testSwal() {
      this.$swal({
        title: 'Errors!',
        text: 'Something went wrong please check all fields',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    activateNameChange(item, bool) {
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(item.uid).set({ License: { "changeTwitchnameAllowed": bool} }, { merge: true }).then(async result => {
        await new Promise(r => setTimeout(r, (20 * 1000)));
      }).catch(err => {
        console.log(err)
      })
    },
    resolveUserData() {
      let userData = getUserData()
      this.isjemkein= userData.id === 'towa5MUabmfPHflpRoxMVCrmDaj2';
    },
    listenForUpdates() {
      console.log("test")
      const sx = this.$firebase.firestore()
      const newDate = new Date();
      let newD =  new Date(newDate.setDate(newDate.getDate() - 2));
      // let newTimeStamp = sx.Timestamp.fromDate(newD)
      let collectionQuery = sx.collection('followerFabrik').doc('orders').collection("vodClip")
      if(this.showOlderOrders) collectionQuery = sx.collection('followerFabrik').doc('orders').collection("follow")
        collectionQuery.get().then(result => {
        console.log("GO")
        this.items = []
        let id = 0
        this.totalActiveViews = 0
        result.forEach(x => {
          const doc = x.data()
          console.log(doc)
          // doc.Botpanel?.
          let userStatus = 0
          if(!doc.finished) {
            userStatus = 2
          }
          else userStatus = 1
          console.log(doc.lastUsed)
          let newObject = {
            id: id,
            geminosId: x.id,
            // eslint-disable-next-line global-require
            alreadySent: doc.added,
            remaining: doc.toAdd,
            orderId: doc.id,
            dripfeedTime: (doc.dripfeedTime/60).toString() + "m",
            totalAdd: doc.totalAdd,
            link: doc.link,
            finished: doc.finished,
            creationDate: doc.creationDate,
            creationDate2:doc.creationDate,
            uid: x.id,
            startingViewCount: doc.startingViewCount,
            finishedViewCount: doc.finishedViewCount,
          }
          console.log(newObject.lastFollow)
          if(newObject.lastFollow === undefined) newObject.lastFollow = new Date()

          console.log(newObject.lastFollow)
          this.items.push(newObject)
          id += 1
        })
        console.log(this.items)

      })
    },
    resolveProgressBarVariant(item) {
      if (item.duration < item.durationTotal / 2 && item.duration > item.durationTotal / 4) return 'primary'
      if (item.duration < item.durationTotal / 4 && item.duration > 1) return 'warning'
      if (item.duration < 1) return 'danger'
      return 'info'
    },
    async restartUser(item) {
      const sx = this.$firebase.firestore()
      sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": false} }, { merge: true }).then(async result => {
        await new Promise(r => setTimeout(r, (20 * 1000)));
            sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": true}, "License": { "LastStart": new Date() } }, { merge: true })
      }).catch(err => {
        console.log(err)
      })
    },
    async instantRestartUser(item) {
      this.waitingActive = true
      const sx = this.$firebase.firestore()
      const userDoc = await sx.collection('User').doc(item.uid).get()
      sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": false, DripFeedTime: 25} }, { merge: true }).then(async result => {
        for(let i = 0; i< 20; i++) {
          await new Promise(r => setTimeout(r, (1000)));
          this.barProgress += 100/40
        }
        const snap2 = await sx.collection('System').doc('NGROK').get()
        await fetch(`https://${snap2.data().Link}.ngrok.io/start/${item.uid}`)
        for(let i = 0; i< 20; i++) {
          await new Promise(r => setTimeout(r, (1000)));
          this.barProgress += 100/40
        }
        sx.collection('User').doc(item.uid).set({ Botpanel: { "Active": true, DripFeedTime: userDoc.data().Botpanel.DripFeedTime}, "License": { "LastStart": new Date() } }, { merge: true })
        this.waitingActive = false
        this.barProgress = 0
      }).catch(err => {
        console.log(err)
      })
    },
    extendPackage(item) {
      const sx = this.$firebase.firestore()
      const obj = {
        TotalDays: item.durationTotal + 1,
      }
      // sx.collection('userTest').doc(item.id).update({ test: obj })
      sx.collection('User').doc(item.uid).set({ License: obj }, { merge: true })
    },
    stopPackage(item) {
      console.log("ENDINGPACKAGE")
      console.log(item)
      const sx = this.$firebase.firestore()
      let obj = {}
      if (item.durationTotal === undefined) {
        obj = {
          licenseKeyOld: item.license, licensekey: 'none', TotalDays: 0, TotalDaysOld: 0,
        }
      } else {
        obj = {
          licenseKeyOld: item.license, licensekey: 'none', TotalDays: 0, TotalDaysOld: item.durationTotal,
        }
      }
      const bpObj = {
        Active: false,
      }
      console.log(obj)
      console.log(item.uid)
      sx.collection('User').doc(item.uid).set({ License: obj, Package: 'None', Botpanel: bpObj }, { merge: true }).then(result => {
        console.log(result)
      }).catch(err => {
        console.log(err)
      })
    },
    resolveViews(item) {
      let amountString = ""
      let statusOfBadge = "light-success"
      if (item.Botpanel?.currentViews === null || item.Botpanel?.currentViews === undefined) {
        amountString= `0 / ${item.Botpanel?.Views}`
      } else amountString= `${item.Botpanel.currentViews}/${item.Botpanel?.Views}`
      let calculatedDate = new Date(item.License?.LastStart.toDate().getTime() + item.Botpanel.DripFeedTime*1000)
      if(item.Botpanel.currentViews-item.Botpanel?.Views < 0) statusOfBadge = "light-danger"
      if(calculatedDate > new Date()) statusOfBadge = "light-warning"
      if(!item.Botpanel.Active) statusOfBadge = "light-secondary"
      let views = {
        amountString,
        statusOfBadge
      }
      return views

    },

    async sendPostRequest(username) {
      const userAgent = {
        Windows: {
          CHROME: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.104 Safari/537.36',
          FIREFOX: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:84.0) Gecko/20100101 Firefox/84.0',
        },
        Linux: {
          CHROME: 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.96 Safari/537.36',
          FIREFOX: 'Mozilla/5.0 (X11; Linux x86_64; rv:85.0) Gecko/20100101 Firefox/85.0',
        },
      }
      const clientId = 'kimne78kx3ncx6brgo4mv6wki5h1ko'
      const jsonOperation = {
        operationName: 'VideoPlayerStreamInfoOverlayChannel',
        variables: {
          channel: username,
        },
        extensions: {
          persistedQuery: {
            version: 1,
            sha256Hash: 'a5f2e34d626a9f4f5c0204f910bab2194948a9502089be558bb6e779a9e1b3d2',
          },
        },
      }

      const response = await fetch(
        'https://gql.twitch.tv/gql', {
          method: 'POST',
          headers: {
            Authorization: 'Oauth c7oso8b4qr09bd3gno0yqykvgv86wy',
            'Client-Id': clientId,
            'User-Agent': userAgent,
          },
          body: JSON.stringify(jsonOperation),
        },
      )
      return response.json()
    },
    resolveStatus(item) {
      console.log(item.Botpanel?.Active)
      if (item.Botpanel?.Active) {
        return 'Started'
      }
      return 'Stopped'
    },
    getFormattedDate(newDate){
      try {
        return newDate.toDate().toLocaleString()
      } catch (e) {
        return "None"
      }
    },
    getTwitchLink(user) {
      return `https://www.twtich.tv/${user.twitchname}`
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
